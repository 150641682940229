.events-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__html {
    iframe {
      width: 100%;
    }
  }

  &__main {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    height: 100%;
    overflow: hidden;
  }

  &__control-panel {
    align-self: flex-end;
    margin-right: 28px;
    margin-bottom: 6vh;
    margin-left: 40px;
    z-index: 50;
    user-select: none;

    @media (max-width: 1156px) {
      margin-right: -40px;
    }

    @media (max-width: 1000px) {
      margin-right: 28px;
    }

    @media (max-width: 650px) {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      top: 220px;
      margin: 0;
      margin-right: 28px;
    }
  }

  &__side-panel {
    align-self: flex-end;
    flex: 0 0 383px;
    display: flex;
    justify-content: flex-end;
    max-height: 100%;
    z-index: 50;
    position: relative;

    @media (max-width: 650px) {
      flex: 1;
      justify-content: center;
    }
  }
}
